// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch(error) {};
            };

        } else {
            xhr = new XMLHttpRequest();
        };

    } else {
        return null;
    };
    return xhr;
};

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest) {
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    } else {
        return(false);
    }
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) {
        return(xhr_object.responseText);
    } else {
        return(false);
    }
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest) {
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    } else {
        return(false);
    }
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);

    if (xhr_object.readyState == 4) {
        return(xhr_object.responseXML);
    } else {
        return(false);
    }
}

// Affichage du bloc qui gere le numero de retour
function showNumRetourEtiquette(idOrder, multiTransp, parentPath, idOrderTransp, idTransporteur) {

    // ELEMENT DU DIV POPUP
    var numDIVObjet = document.getElementById('popup_numero_retour');

    //Test pour verifier si il s'agit d'une commande multitransporteur
    if (multiTransp == 1) {
        var numObjet = document.getElementById('num_retourDIV_' + idTransporteur);
        var divPrintPdfObjet = document.getElementById('printPdf_' + idTransporteur);
    } else {
        var divPrintPdfObjet = document.getElementById('printPdf');
        var numObjet = document.getElementById('num_retourDIV');
    }
    var numDiv_coli = document.getElementById('colissimo');
    var numDiv_mory = document.getElementById('mory');

    // ELEMENT DU BLOC DANS LE DETAIL
    if (multiTransp == 1) {
        var divCreateNumRetourObjet = document.getElementById('createNumRetour_' + idTransporteur);
        var numRetourObjet          = document.getElementById('num_retour_' + idTransporteur);
    } else {
        var divCreateNumRetourObjet = document.getElementById('createNumRetour');
        var numRetourObjet          = document.getElementById('num_retour');
    }

    var numRetour = '';
    switch (idTransporteur) {
        case 1:
            if (numDiv_coli ) numDiv_coli.style.display = 'block';
            if (numDiv_mory ) numDiv_mory.style.display = 'none';
            break;
        case 2:
        case 4:
        case 5:
        case 21:
        case 25:
            if (numDiv_coli ) numDiv_coli.style.display = 'none';
            if (numDiv_mory ) numDiv_mory.style.display = 'block';
            break;
    }

    var ajaxUrl = parentPath + 'ajax_num_etiquette.php?idOrder=' + idOrder + '&multiTransp=' + multiTransp;
    if (typeof(idOrderTransp) != 'undefined') {
        ajaxUrl += '&idOrderTransp=' + idOrderTransp;
    }

    var numRetour = ajax_file(ajaxUrl);

    // Securite : la commande n'appartient pas au client connecte
    if (numRetour != 'false') {
        numDIVObjet.style.display = 'block';
        numObjet.innerHTML = numRetour;

        numRetourObjet.innerHTML = numRetour;
        divCreateNumRetourObjet.style.display = 'none';
        divPrintPdfObjet.style.display = 'block';

        document.getElementById('shad').style.display = 'block';
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp) {
    //Test pour verifier si il s'agit d'une commande multitransporteur
    if (multiTransp == 1) {
        var choixRetourDivObjet = document.getElementById('choixRetourDiv_');
        var choixRetourObjet = document.getElementById('choixRetour_');
    } else {
        var choixRetourDivObjet = document.getElementById('choixRetourDiv');
        var choixRetourObjet = document.getElementById('choixRetour');
    }
    var choix = (choixRetourObjet.value != '0') ? choixRetourObjet.value : choixRetourDivObjet.value;

    switch (choix) {
        case '1':
            var txtRaison = 'Droits de retractation';
            break;
        case '2':
            var txtRaison = 'Article non conforme e la commande';
            break;
        case '3':
            var txtRaison = 'Article Casse / Defectueux';
            break;
        case '4':
            var txtRaison = 'Probleme de Taille / Qualite';
            break;
        case '5':
            var txtRaison = 'Autres';
            break;
    }

    var response = ajax_file( parentPath + 'ajax_create_etiquette.php?idOrder=' + idOrder + '&choix=' + txtRaison + '&numChoix=' + choix +'&multiTransp=' + multiTransp);
    if (response.substr(0,5) == 'false') {
        alert( ajax_file( parentPath + 'ajax_sprintf.php?arg1=js_error_return_label ' ) );
    } else {

        var listeRaisonBlocObjet = document.getElementById('liste_motif_retour');
        var btnPrintPdfBlocObjet = document.getElementById('btnPrintPdf');

        if (txtRaison == "Autres") {
            listeRaisonBlocObjet.innerHTML = '<u>Raison du retour</u> : ' + txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
        } else {
            listeRaisonBlocObjet.innerHTML = txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
        }

        btnPrintPdfBlocObjet.style.display = 'block';

        document.getElementById('shad').style.display = 'none';
        document.getElementById('popup_numero_retour').style.display = 'none';

        window.open(response);
    }

}

// Affiche la liste des sous categories d'un produit
function showSousCategOptionListe(objCateg, objSousCategId, objetId, idSousCateg) {

    var optionSousCateg = document.getElementById(objSousCategId);
    var optionObjet = document.getElementById(objetId);

    if (objCateg.value != 'non_attribue') {

        var response = ajax_file('../ajax/ajax_sousCateg_optionliste.php?idCateg=' + objCateg.value
            + '&index=' + objSousCategId.split('_')[1] + '&idSousCateg=' + idSousCateg);

        optionSousCateg.style.display = 'block';
        optionObjet.style.display = 'none';
        if (response == '') { optionSousCateg.innerHTML = ''; optionObjet.innerHTML = ''; }
        else { optionSousCateg.innerHTML = response; optionObjet.innerHTML = ''; }

    } else {
        optionSousCateg.innerHTML = '';
        optionObjet.innerHTML = '';
    }
}
// --------------------------------------------------------------------------------

// Affiche la liste des objets d'un produit
function showObjetOptionListe(objSousCateg,objetId, idObjet) {
    var optionObjet = document.getElementById(objetId);
    var response = ajax_file('../ajax/ajax_objet_optionliste.php?idSousCateg=' + objSousCateg.value
        + '&index=' + objetId.split('_')[1] + '&idObjet=' + idObjet);
    optionObjet.style.display = 'block';
    optionObjet.innerHTML = (response) ? response : '';
}
// --------------------------------------------------------------------------------

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
    var options = xmlDoc.getElementsByTagName('option');
    var optionsListe = new Array();
    for (var i=0; i < options.length; ++i) {
        var optionsListe2 = new Array();
        var id = options.item(i).getElementsByTagName('id');
        var label = options.item(i).getElementsByTagName('label');
        var color = options.item(i).getElementsByTagName('color');
        optionsListe2.push(id.item(0).firstChild.data,label.item(0).firstChild.data,color.item(0).firstChild.data);
        optionsListe.push(optionsListe2);
    }
    return optionsListe;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = 'couleurProd';
    var tailles_id  = 'tailleProd';

    if (elementID != 'liste_qte') {
        couleurs_id += '_'+idProduit;
        tailles_id  += '_'+idProduit;
    }

    var listQte = $('#'+elementID);
    var alternative_listQte = $('#liste_qte_spe');

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');

            var couleurs = ( ($('#'+couleurs_id).length) ? $('#'+couleurs_id).val() : 'none' );
            var tailles = ( ($('#'+tailles_id).length) ? $('#'+tailles_id).val() : 'none' );
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = 'none';
            var tailles = 'none';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            $('.selectStyled').each(function() {
                $(this).selectStyled();
            });

            var select_stock = $('#qteProd');
            var regexp_epuise = /Stock /;
            var txt_stock = $('#qteProd>option:selected').text();

            checkStockCases();
            refreshProductPageElements($('form.product_info', '#site_global_wrap').get(0));
        }
    }
}

function showQteListeGamme(elementID, idProduit,parentPath) {

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById('couleurProd_'+idProduit)) var couleurs = document.getElementById('couleurProd_'+idProduit).value;
            if (document.getElementById('tailleProd_'+idProduit)) var tailles = document.getElementById('tailleProd_'+idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = '';
        if ($("#produit_lot_nb_"+idProduit).val() != undefined) {
            string_params += '&produit_lot_nb=' + $("#produit_lot_nb_"+idProduit).val();
        }

        if ($("#qte_default_"+idProduit).val() != undefined) {
            string_params += '&qte_default=' + $("#qte_default_"+idProduit).val();
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

            $('.selectStyled').each(function() {
                $(this).selectStyled();
            });
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {

    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);
    listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById('couleurProdAssoc_'+idProduit);
    var tailles = document.getElementById('tailleProdAssoc_'+idProduit);
    if (listQte || alternative_listQte) {
        if (listQte) {
            var couleurs = document.getElementById('couleurProd_'+idProduit).value;
            var tailles = document.getElementById('tailleProd_'+idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = 'none';
            var tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs.value + '&idtaille=' + tailles.value);
        if (response) {
            // We execute twice because replace only once while we have two occurences
            response = response.replace( 'qteProd_', 'qteProdAssoc_' );
            response = response.replace( 'qteProd_', 'qteProdAssoc_' );
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

        }
    }
}

// Verifie si le produit ou la commande existe
function verif_base(elementID, val, parentPath) {
    var mess = document.getElementById(elementID);
    if (elementID=='mess_num_commande') var variable = 'numCommande';
    else var variable = 'refProduit';

    if (mess) {
        var response = ajax_file(parentPath + 'ajax_verif_contact.php?'+variable+'='+ val );
        responce = $.parseJSON(response);
        var result = responce[0];
        var response = responce[1];
        if (response) {
            mess.innerHTML = response;
            var nom_label = document.getElementById('contactNomLabel');
            var td_img = document.getElementById('preview_tof');

            if (variable=='refProduit') {
                if (!document.getElementById('erreur')) {
                    var img_name = document.getElementById("img_name").value;
                    var img_rep = document.getElementById("dir").value;
                    var img_type = document.getElementById("image_type").value;
                    var td = document.createElement('td');
                    td.setAttribute('id','preview_tof');
                    if (document.all) {
                        td.style.setAttribute('cssText','padding-left:45px;');
                        if (img_type=='vert') td.style.setAttribute('cssText','padding-left:45px;vertical-align:top;padding-top:7px;');
                    } else {
                        td.setAttribute('style','padding-left:45px;');
                        if (img_type=='vert') td.setAttribute('style','padding-left:45px;vertical-align:top;padding-top:7px;');
                    }
                    td.rowSpan=5;

                    var img = document.createElement('img');
                    var dest_img = img_rep+img_name+'.jpg';
                    img.setAttribute('src', dest_img);
                    img.setAttribute('id',img_name);

                    if (img_type=='hor') img.setAttribute('class','cadre_photo_hor');
                    else img.setAttribute('class','cadre_photo_vert');

                    td.appendChild(img);
                    if (!td_img) nom_label.parentNode.parentNode.appendChild(td);
                } else {
                    var nom_label = document.getElementById('contactNomLabel');
                    if (td_img) nom_label.parentNode.parentNode.removeChild(td_img);
                }
            }
        }
        else mess.innerHTML = "";
    }
}

function showTailleListeGamme(elementID, idProduit,parentPath, tailleProd) {
    var eltaille = document.getElementById( elementID );

    if (eltaille) {
        var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);

        if (response) {
            eltaille.innerHTML = response;
        }
    }
}

function showTailleListeAssoc(elementID, idProduit, parentPath, tailleProd) {

    var eltaille = document.getElementById( elementID );

    if (eltaille) {
        var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);

        if (response) {

            response = response.replace('tailleProd_', 'tailleProdAssoc_');
            response = response.replace('tailleProd_', 'tailleProdAssoc_');
            response = response.replace('liste_qte_', 'liste_qte_assoc_');
            response = response.replace('showQteListeGamme', 'showQteListeAssoc');
            eltaille.innerHTML = response;
        }
    }
}

function checkStocksProduct() {
    var id_product_element = document.getElementById('produit_id');

    if (id_product_element) {
        var id_product = id_product_element.value;
        var response = ajax_file(parentPath + 'ajax/ajax_check_stock.php?id_product='+id_product);

        if (response && response < 1) {
            alert(ajax_file(parentPath+'ajax_sprintf.php?arg1=js_error_no_stock'));
            return false;
        }

        return true;
    }
}

// Classe les categories
function ajax_classer() {
    var sortable1 = Sortable.serialize('list1');
    var sortable2 = Sortable.serialize('list2');
    var response = ajax_file('ajax/ajax_classer_categ.php?' + sortable1 + '&' + sortable2);

    if (response) {
        var message = document.getElementById('messFinish');
        message.style.display = "block";
        Effect.Pulsate(message);
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {

    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
        var response = ajax_file('ajax/ajax_liste_qte_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&i=' + i + '&panierId=' + paniervirtuelId );
        if (response) {
            listQtePanier.innerHTML = response;
        }
    }
}

function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById('couleurProd'+i);
    var tailles = document.getElementById('tailleProd'+i);
    var qte = document.getElementById('ProdQte'+i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file('ajax/ajax_upd_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte.value + '&panierId=' + paniervirtuelId);
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {

    if (i != '') {
        i = '_' + i;
        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);
    if ($('#lightbox_achat_express').css('display')=='block') {
        couleurs = $('#lightbox_achat_express #couleurProd' + i);
        tailles = $('#lightbox_achat_express #tailleProd' + i);
    }
    var $alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
        $('body').append($alertbox.hide());
    }

    if (tailles.length && tailles.val() == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.val() == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (($('#lightbox_achat_express').css('display')=='block' && $('#lightbox_achat_express #qteProd' + i).val() == '')
        || ($('#lightbox_achat_express').css('display')!='block' && document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '')) {

        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');

        return false;
    } else {
        var qte = 0;
        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        if ($('#lightbox_achat_express').css('display')=='block') {
            qte = $('#lightbox_achat_express #qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();

        if (tailles && tailles.val() != 'none' && tailles.val() != undefined)
            idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        try {
            arrayResponse = JSON.parse(response);
            if (typeof arrayResponse.error !== 'undefined') {
                $alertbox.find('.txt_alert').html(arrayResponse.error.msg);
                openMultiShad('alertbox1', 'fixed');
                return false;
            }
        } catch (e) {}
        if (response) {
            array_response = response.split("//");
            var designation = array_response[0];
            if (titreObjet.match('^[0-9]')) {
                if (designation == 1) {
                    designation += ' lot de';
                } else {
                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                } else if (lastTaille != '') {
                    designation += ' - Taille ' + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {
                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;
        }
        // dataleyr V2 add Cart
        if (array_response[5] !== undefined) {
            pushIntoDatalayer(array_response[5]);
        }
        return array_response;
    }
}

function showMontanTotalProduit(elementID, i) {
    var montantProduit = document.getElementById(elementID);
    qte = document.getElementById("ProdQte"+i);
    prix = document.getElementById("prixProd"+i);

    var response = ajax_file('ajax/ajax_montant_total_produit.php?qte=' + qte.value + '&prix=' + prix.value );

    if (response) {
        montantProduit.innerHTML = response;
    }
}

function showMontanTotalPanier(elementID) {
    var montantTotalPanier = document.getElementById(elementID);
    var response = ajax_file('ajax/ajax_montant_total_panier.php');

    if (response) {
        montantTotalPanier.innerHTML = response;
    }
}

// Displays the list of questions appropriate to the service requested
function show_question(elementID, fieldID, idService, parentPath) {
    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {
        $.ajax({
            url : path_relative_root + create_link('ajax_list_question_contact'),
            type : 'post',
            data : {idService : idService},
            success : function (res) {
                list_question.innerHTML = res;
                field.innerHTML = '';
                $('.selectStyled', list_question).selectStyled();
            }
        });
    }
}

// Affiche les champs appropriees au service et e la question demande
function show_fields(elementID, idService, idQuestion, parentPath) {

    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {
        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);
        $('.contact-beware a').attr('href',parentPath + create_link('connexion_login') + '?from=contact&id_service=' + idService + '&id_question=' + idQuestion);
        if (response) {
            list_field.innerHTML = response;
            $( "#produit_ref_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_ref');
            });

            $( "#produit_nom_input" ).bind( "keyup", function() {
                // Recuperation de tous les produit
                getAllProduct('produit_nom');
            });

            $('.selectStyled', list_field).each(function () {

                $(this).selectStyled();
            });

            $(function () {
                $('.styled').new_styling_form({
                    type_class_sufix: '',
                    localName_new_elem: 'span'
                });
            });

            tabError = [];

            tabError[tabError.length] = ['alpha', 'contactNom', 'contact_field_nom'];
            tabError[tabError.length] = ['alpha', 'contactPrenom', 'contact_field_prenom'];
            tabError[tabError.length] = ['mail', 'contactMail', 'contact_field_mail'];

            if (idService == 7) {

                tabError[tabError.length] = ['alphanum','contactAdresse','contact_field_adresse'];
                tabError[tabError.length] = ['alphanum','contactCP','contact_field_cp'];
                tabError[tabError.length] = ['alphanum','contactVille','contact_field_ville'];
                tabError[tabError.length] = ['alphanum','contactPays','contact_field_pays'];

            } else {
                if (idService == 2 && idQuestion == 5) {
                    tabError[tabError.length] = ['alphanum','contactRefProduit','contact_field_ref_produit'];
                }

                if (idService == 14) {
                    if (idQuestion == 3) {
                        tabError[tabError.length] = ['alphanumadresse', 'contactAdresse', 'contact_field_adresse'];
                        tabError[tabError.length] = ['phone', 'contactNumPortable', 'contact_field_num_portable'];
                        tabError[tabError.length] = ['alphanum', 'contactCP', 'contact_field_cp'];
                        tabError[tabError.length] = ['alphanumadresse', 'contactVille', 'contact_field_ville'];
                        tabError[tabError.length] = ['select_date', 'garantieDateAchat', 'date_achat'];
                        tabError[tabError.length] = ['prix', 'contactPrix', 'prix'];
                        tabError[tabError.length] = ['alphanum', 'id_produit_ref', 'date_achat'];

                        $("#telephoneFake").intlTelInput({});

                        // Date Achat validation
                        $('#btn_contact').click(function (e) {

                            e.preventDefault();

                            if ((document.getElementById('garantieDateAchatM').value === '') || (document.getElementById('garantieDateAchatA').value === '') || (document.getElementById('garantieDateAchatJ').value === '')) {
                                $('#garantieDateAchatLabel').addClass('labelErr');
                            } else {
                                $('#garantieDateAchatLabel').removeClass('labelErr');
                                $('#garantieDateAchatLabel').siblings('.img_error').remove();
                            }

                            if ($('#id_produit_ref').val() == '') {
                                $('#produit_ref_input').attr('class', 'inputErr');
                                $('#produit_nom_input').attr('class', 'inputErr');
                                $('#id_produit_refLabel').attr('class', 'labelErr');
                                $('#produit_nom_inputLabel').attr('class', 'labelErr');

                                $('#id_produit_ref').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#produit_nom_input').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');

                            }
                            else {
                                $('#produit_ref_input').attr('class', 'inputForm');
                                $('#produit_nom_input').attr('class', 'inputForm');
                                $('#id_produit_refLabel').attr('class', '');
                                $('#produit_nom_inputLabel').attr('class', '');
                            }

                            if ($('#contactMagasin').val() == '') {
                                $('#contactMagasin').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#contactMagasinLabel').addClass('labelErr');
                            }
                        });
                    } else if (idQuestion == 2) {

                        var content = ajax_file('ajax_field_active_garantie.php' );

                        if (content) {

                            $('#select_garantieNomProduit').append( content);
                            $('#field_garantieNomProduit').removeClass('cache');
                            $('#field_error_garantieProduit').addClass('cache');
                            $('#field_photo').addClass('cache');
                            $('#field_error_garantieProduit td').empty();
                        } else {

                            $('#field_garantieNomProduit').addClass('cache');
                        }
                    }
                }
            }

            // 14 réclamation
            if (!(idService == 3 && idQuestion == 2) && idService != 7 && idService != 14) {
                tabError[tabError.length] = ['no_empty', 'contactMessage', 'contact_field_message'];
            }

            if ($('#g-recaptcha').length > 0) {
                grecaptcha.render('g-recaptcha');
            }
        } else {
            list_field.innerHTML = '';
        }
    }
}

function show_field_form(parentPath) {
    var list_field = document.getElementById('field');
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=7&idQuestion=1');
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array('alpha','contactNom','contact_field_nom');
            tabError[1] = new Array('alpha','contactPrenom','contact_field_prenom');
            tabError[2] = new Array('mail','contactMail','contact_field_mail');
            tabError[3] = new Array('alphanum','contactAdresse','contact_field_adresse');
            tabError[4] = new Array('alphanum','contactCP','contact_field_cp');
            tabError[5] = new Array('alphanum','contactVille','contact_field_ville');
            tabError[6] = new Array('alphanum','contactPays','contact_field_pays');

            $('.selectStyledFaq, .selectStyled, .selectStyledProduct').each(function() {
                $(this).selectStyled();
            });
        } else {
            list_field.innerHTML = '';
        }
    }
}

// Affiche les produits de la commande appropriees au service litige // Contact
function show_product_commande(idCommande, parentPath) {

    var list_field =  document.getElementById('field_produit_commande');
    if (idCommande) {
        var response = ajax_file(parentPath + 'ajax_field_product_commande.php?idCommande=' + idCommande);
        if (response) {
            list_field.innerHTML = response;
            $('#produitCommande').removeClass('cache');
        }
    } else {
        list_field.innerHTML = "";
        $('#produitCommande').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
    }
}

// Affiche si produit est encore sous garantie
function show_product_garantie(idCommande, idProduit, parentPath) {

    if (idCommande && idProduit) {
        var response = ajax_file(parentPath + 'ajax_field_product_garantie.php?idCommande=' + idCommande + '&idProduit=' + idProduit);
        if (response) {

            // ERREUR SI PRODUIT RETOURNE
            if (response == "Produit Retourne") {

                document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_produit_retourne');
                $('#field_message').addClass('cache');
                $('#field_photo').addClass('cache');
            } else {
                // GESTION DES ERREURS
                tab = new Array();
                tab[0] = new Array('alpha','contactNom','contact_field_nom');
                tab[1] = new Array('alpha','contactPrenom','contact_field_prenom');
                tab[2] = new Array('mail','contactMail','contact_field_mail');
                tab[3] = new Array('no_empty','contactMessage','contact_field_message');

                $('#field_message').removeClass('cache');
                $('#field_photo').removeClass('cache');
                document.getElementById('field_error_produit_garantie').innerHTML = "";
            }
        } else {
            document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_garantie');
            $('#field_message').addClass('cache');
            $('#field_photo').addClass('cache');
        }
    } else {
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    if (!$('.see_all_product').hasClass('loading')) {
        $('.see_all_product').addClass('loading');
    }

    var page_bloc;
    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

    page = page || 1;

    $.ajax({
        url : path_relative_root + create_link('ajax_reload_pagination'),
        type : 'get',
        data : {
            page : page,
            link : 'paginationGoToPage()',
            nb_total_page : nb_total_page
        },
        success : function (res) {

            // Don't count pushs so only target vitem (hesperide)
            var productLoaded = $('.items_wrapper .item.vitem').length;

            if (productLoaded > total_element) {
                total_element = productLoaded;
            }

            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {

                if (see_all === 0) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">'+ Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: '+ percentLoaded +'%;"></span></div><div class="see_all_product" onclick="seeXProduct(' + (nb_prod + 1) + ', ' + page +  ')">' + Translator.translate('see_more_products') + '</div>';
                } else if (see_all === 1) {
                    this.innerHTML = '<div class="nbProdDisplayed">'+ Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: '+ percentLoaded +'%;"></span></div><div class="see_all_product loading" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }

                if (productLoaded == total_element) {
                    $('.pager_wrapper.bot_pager .see_all_product').hide();
                } else {
                    $('.pager_wrapper.bot_pager .see_all_product').show();
                }
            });

            initDailymotionVideos();
        }
    });
}

function seeXProduct(nb_product, page) {
    if (!$('.pager_wrapper.bot_pager .see_all_product').hasClass('loading')) {
        $('.pager_wrapper.bot_pager .see_all_product').addClass('loading');
    }

    var form = document.getElementById('filter_sticky'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);

    // To display everything, start from the current page
    if (nb_product === (nb_prod_default + 1)) {
        // Force initial value to maintain page organization
        nb_product = nb_prod;
        $("#see_all").val("1");
        generateNewBlocProd((page - 1));
        $("#initial_page").val(page);
    } else {
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);
        // If we’re on the search page
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {

    if (this.checked && !evt.ctrlKey) {
        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
    }

    generateNewBlocProd.call(this.form, 0);
}

function checkHeight(id) {
    var filterContainer = $('#filter_container');
    var filterContainerFixed = $('#filter_container.fix_height');
    var filterHeight = $('#' + id).find('.wrap_filter').innerHeight();

    if ($("#filter_container").hasClass('fix_height')) {
        filterContainerFixed.css( "margin-bottom", (70 + filterHeight) + "px" );
    } else {
        filterContainer.css( "margin-bottom", "70px" );
    }
}

/**
 * Used to display products
 * @param {*} page 
 * @param {*} type 
 * @param {*} idObj 
 * @param {*} nb_product 
 * @param {*} btn 
 * @param {*} fromScrollBottom 
 * @param {*} fromScrollTop 
 */
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {},

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');

    if (page !== undefined && page !== 'all') {
        if (page != 0) {
            output.page = $('#scroll_items .vitem').length;
        }
    }

    if (page === 'all') {
        /** LEGACY **/
        var hash;
        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0]) || page_value > 0) {
            var marginTop;
            if ($('.wrapper_bandeau')) {
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + '/' + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8])[8].en);
                $("link[rel^='alternate'][hreflang^='es-es']").attr("href", JSON.parse(res[8])[7].es);
                $("link[rel^='alternate'][hreflang^='de-de']").attr("href", JSON.parse(res[8])[9].de);
                $("link[rel^='alternate'][hreflang^='it-it']").attr("href", JSON.parse(res[8])[10].it);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');
                
                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext,commentNext, 'next');

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {}
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {}
                    }

                    /** END LEGACY **/
                } else {
                    old_elm.innerHTML = '';
                    document.getElementById('totalElems').value = itm_length;
                }

                //validate_filter
                $("#validate_filter").html('<span>' + Translator.translate("see") + " (" + itm_length +")" + '</span>');
            },
            complete: function () {
                //force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {

                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: ($(window).scrollTop() + 10)
                        }, 200);
                    }, 250);
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if (fromScrollBottom) {
                    $("#initial_page").val(page +1);
                }

                /**
                * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                */
                var total_element = document.getElementById('totalElems').value;
                var productLoaded = $('.items_wrapper .item').length;
                var percentLoaded = (productLoaded*100/total_element);
                // Update div nb product
                $('.nb_products').text(total_element > 1 ? Translator.translate('x_products', total_element) : Translator.translate('x_product', total_element));

                var see_all = $('#see_all').val();
                if (see_all === 0) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">'+ Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"></div><div class="see_all_product" onclick="seeXProduct(' + (nb_prod + 1) + ', ' + page +  ')">' + Translator.translate('see_more_products') + '</div>';
                } else if (see_all === 1) {
                    this.innerHTML = '<div class="nbProdDisplayed">'+ Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"></div><div class="see_all_product loading" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                    $('.nbProdDisplayed').html(Translator.translate('no_product_found'));
                    percentLoaded = 0;
                }

                $('.loadedGauge').html(
                    (percentLoaded != 0)
                    ? '<span style="width: '+ percentLoaded +'%;"></span>'
                    : ''
                );

                $('.txt_bandeau label').html('(' + total_element + ' ' + Translator.translate('2_product') + ' )');

                if ($('.loader_scroll').hasClass('loading')) {
                    setTimeout(function () {
                        $('.loader_scroll').removeClass('loading');
                    }, 1200);
                }

                if (productLoaded == total_element) {
                    $('.pager_wrapper.bot_pager .see_all_product').hide();
                } else {
                    $('.pager_wrapper.bot_pager .see_all_product').show();
                }
            }
        });
    }
}

function addFilter(name,id) {
    $('#block_selected_filter').append('<span data-obj_id="'+id+'" class="selected_filter">'+name+'</span>');
    $('span[data-obj_id="'+id+'"]').on("click", deleteFilter);
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases e cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function(elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;

    }

    // Add misc values to output object
    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;
    output.keyword = form.keyword.value;
    output.nb_prod = form.nb_prod.value;
    output.nb_prod_default = form.nb_prod_default.value;
    output.totalElems = form.totalElems.value;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + create_link('ajax_reload_search'),
        type: "get",
        data: output,
        success: function(res) {
            var old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {

                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top-curOffset);

                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /** LEGACY **/

                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/
                fix_rayon_display();
            }
        },
        complete: function() {
            //force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }

            $('.category #site_global_wrap').addClass('fullyLoaded');
        },
    });
}

/**
 * Permet d'afficher les commandes par date (on va selectionné l'onglet sur lequel on est)
 * @param elementID
 */
function show_orders_by_date(elementID) {
    // On recupère le type qui est selectionné
    var type = $('#com_onglet .onglet.actif').data('type');
    show_orders_by_type(elementID, type, 1);
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();
    if (year != '') {
        str_year = '&year='+year;
    }

    if (typeof(reload) != 'undefined') {
        str_reload = '&reload=true';
    }

    var container = document.getElementById(elementID);

    if (container) {
        $.ajax({
            url : path_relative_root + create_link('ajax_account_orders_by_type'),
            data : 'type='+ type + str_year + str_reload
        }).done(function(response) {
            if (response) {

                var $response = $(response);

                if (typeof(reload) != 'undefined') {
                    $('.content_commandes').html( $response.html());
                } else{
                    $("#pluriel_order").html((nb_order_current>1)?'s':'');
                    $(container).html($response);
                }

                var nb_order_current = $('#com_onglet_'+type+' .nb_order').html();
                var label_state_order = $('#com_onglet_'+type+' .label_order').html();

                $("#nb_current_type_order").html(nb_order_current);
                $("#label_state_order").html(label_state_order);

                if (nb_order_current > 0) {

                    $('#com_onglet li.actif').removeClass('actif');
                    $('#com_onglet_'+type).addClass('actif');

                }
                $('.styled').new_styling_form({
                    type_class_sufix : '',
                    localName_new_elem : 'span'
                });
            } else {
                $(container).html('');
            }
        });
    }
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr('id'),
        mId = id.substring(id.indexOf('_')+1),
        messageContainer = $('.message_account_container');

    if (messageContainer.hasClass('appear')) {
        messageContainer.removeClass('appear');
    } else {
        messageContainer.addClass('appear');
    }

    if (!(elem).hasClass('actif')) {
        $('.message_detail').slideUp();
        $('.btn_ouvrir_message').not(elem).removeClass('actif');
        $('.btn_ouvrir_message').not(elem).text(msg_open);

        if ($('#msg_'+mId).html() == '') {

            $.ajax({
                type: 'post',
                data: { mId: mId, type: 1 },
                url: path_relative_root+create_link('message_detail'),
                success: function( response) {
                    $('#msg_'+mId).html(response);
                    $('#msg_'+mId).slideDown();
                    $('.answerTo').removeClass('cache');

                }
            });
        } else {
            $('#msg_'+mId).slideDown();
        }
        (elem).addClass('actif');
        (elem).text(msg_close);
        $('.answerTo').addClass('cache');
    } else {
        $('.message_detail').slideUp();
        (elem).removeClass('actif');
        (elem).text(msg_open);
        $('.answerTo').addClass('cache');
        $('#msg_'+mId).html('');
    }
}

function show_message(mId, hide) {

    if (hide == 0) {
        var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId);
        if (ajax_content) {

            document.getElementById("message_"+ mId).innerHTML = ajax_content;

            document.getElementById("message_"+ mId).onclick = '';
            document.getElementById("message_"+ mId).style.cursor = 'default';

        }
    } else {
    var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId + '&hide=1');
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;
            document.getElementById("message_" + mId).onclick = "show_message("+mId+",0);";
            document.getElementById("message_" + mId).style.cursor = 'pointer';
        }
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {

    var flag = verif_mail( 'email_inscription_home','login_email' );

    if (flag) {

        flag = ajax_file(path_relative_root+'ajax_email_newsletter_exist.php?mail='+ document.getElementById('email_inscription_home').value);

        var elementID = 'email_inscription_home';

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + 'Label');
        var erreurText = document.getElementById('erreur_msg');

        if (flag === '1') {
            flag = false;
        } else {
            flag = true;
        }

        if (!flag) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path+'ajax_sprintf.php?arg1=login_email') + ' :</p>' + 'Cet email est déjà inscrit à la newsletter';
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';

        } else {

            objElement.className = 'inputForm';
            objElement.style.border="1px solid #CCCCCC";
            objLabel.className = 'labelForm';

        }
    }

    if (flag) {

        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = 'none';
        document.getElementById("img_error_popup").style.display = 'none';

    } else {

        var val = document.getElementById('erreur_msg').innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css('display','inline-block');

        elem_img.setAttribute('onmouseover',function() {OverPopup(this,val,'visible','cmpid_adwords');});
        elem_img.onmouseover=function() {
            OverPopup(this,val,'visible','cmpid_adwords');
        };
        elem_img.setAttribute('onmouseout',function() {OverPopup(this,val,'none','cmpid_adwords');});
        elem_img.onmouseout=function() {OverPopup(this,val,'none','cmpid_adwords');};

        elem = document.getElementById('email_inscription_home');

        elem.style.border="1px solid red";
        pos = getPos(elem_img);

        document.getElementById('bloc_erreur').style.position = 'absolute';

        var nav = navigator.appVersion;

        if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        } else {
            document.getElementById("bloc_erreur").style.display = 'block';
        }

        var clone = $('#bloc_erreur').clone(),
        c_pos = {
            top : $('#img_error_popup').offset().top - $('#img_error_popup').offsetParent().offset().top - $('#bloc_erreur').height() + 4,
            left : $('#img_error_popup').offset().left - $('#img_error_popup').offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($('#img_error_popup').width() * 2) + 6
        };

        $('#bloc_erreur').remove();
        $('#img_error_popup').after(clone);

        $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
            'float' : 'right',
            'margin' : '0 7px'
        });

        $('#bloc_erreur').css({
            'position' : 'absolute',
            'top' : c_pos.top,
            'left' : c_pos.left+271,
            'z-index' : '99999',
            'opacity' : '1'
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')",5000);
    }

    return flag;

}

function checkAndAddMail() {
    if (newNewsletterFromCmpid()) {
        $("#btn_conf_cmpid").css('display','none');
        $("#loader_popup").css('display','block');
        response = ajax_file(path_relative_root+'ajax_add_email_newsletter.php?mail='+ document.getElementById('email_inscription_home').value+'&from=lightbox');
        if (response == 1) {
            $("#formu_inscription_news").fadeOut(750,function() {
                $("#conf_inscription_news").fadeIn(750);
            });
            document.getElementById('shad').setAttribute('onclick',function() { close_popup('popup_inscription_home'); });
            document.getElementById('shad').onclick=function() { close_popup('popup_inscription_home');}
        }
    }
}

function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;

}

/** wishlist **/
function addToWishlistShoppingcart() {
    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function(resp) {
        $(".btn_top_wishlist .gris").html('(x' + resp.result.wishlist_size + ')');
        $("#addToWishlistShoppingcart").removeClass("addToWishlistShoppingcart").addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").attr("onclick","").attr("href", path_relative_root + create_link('client_index') +'#wl');
    });
}

function deletePromoCode() {
    $.ajax({
        type: 'get',
        url: path_relative_root + create_link('delete_promo_code'),
        success: function(response) {
            location.href = path_relative_root + create_link('order_kiala');
        }
    });
}

/********display_lightbox_specifique********/
function display_lightbox_specifique() {

    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php');

    if (html) {
        var $lighbox = $( '#lightbox_achat_express' );
        var $shad = $( '#shad' );
        $lighbox
        .html( '<div style="position: relative">'+ html +'</div>' )
        .appendTo( 'body' )
        .show()
        ;
        $shad
        .show()
        .unbind( 'click.achat_express' )
        .bind( 'click.achat_express', function() {
        hideLightboxGamme();
        $("#alertbox").remove();
        })
        ;
    }

}
/*********/

/********add_produit_specifique********/
function add_produit_specifique(product_id, color_bloc_id, size_bloc_id) {

    var color_id = 0;

    if (document.getElementById(color_bloc_id ) )
        color_id = document.getElementById( color_bloc_id ).value;

    var size_id = 0;

    if (document.getElementById(size_bloc_id ) )
        size_id = document.getElementById( size_bloc_id ).value;

    if (document.getElementById(size_bloc_id ) && size_id == '') {
        var taille_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_size');
        $("#label_alert").html(taille_label);

        has_errors = true;

        var $alertbox = $( '#alertbox' );

        $alertbox
            .appendTo( 'body' )
            .show()
            .css('z-index','10000')
        ;

        if (document.getElementById('lightbox_achat_express')) {
            $alertbox.css({
                left:   '52%',
                top:    '490px',
                position:   'fixed'
            });
        }
        addEvent(shad,'click',closeMultiShad);
        $("#bloc_btn_loader").hide();
        document.getElementById("bloc_btn_active").style.display="block";

    }
    else if (document.getElementById(color_bloc_id ) && color_id == '') {
        var couleur_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_color');
        $("#label_alert").html(couleur_label);

        has_errors = true;

        var $alertbox = $( '#alertbox' );

        $alertbox
            .appendTo( 'body' )
            .show()
            .css('z-index','10000')
        ;

        if (document.getElementById('lightbox_achat_express')) {
            $alertbox.css({
                left:   '52%',
                top:    '490px',
                position:   'fixed'
            });
        }
        addEvent(shad,'click',closeMultiShad);
        $("#bloc_btn_loader").hide();
        document.getElementById("bloc_btn_active").style.display="block";

    } else {

        var retour = ajax_file(path_relative_root + 'ajax_add_produit_specifique.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id );

        if (retour == 'continue') {
            var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php' );

            if (html) {
                $("#bloc_btn_loader").hide();
                document.getElementById("bloc_btn_active").style.display="block";
                $( '#lightbox_achat_express' ).html( '<div style="position: relative">'+ html +'</div>' );
                $("#bloc_btn_loader").hide();
                document.getElementById("bloc_btn_active").style.display="block";
            }

        } else {
            location.href = path_relative_root + create_link('order_basket');
        }
    }
}
/*********/

function checkActiveGarantie(reponse) {
    if (reponse == 0) {
        $('#field_error_garantieProduit').removeClass('cache');
        $('#field_error_garantieProduit td').empty();
        $('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_field_active_garantie' ));
        $('#select_garantieNomProduit').empty();

        $('#field_garantieNomProduit').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_send').addClass('cache');
    } else {
        var dateJ = $('#contactDateCommandeJ').val();
        var dateM = $('#contactDateCommandeM').val();
        var dateA = $('#contactDateCommandeA').val();

        var content = ajax_file('ajax_field_active_garantie.php?dateJ=' + dateJ + "&dateM=" + dateM + "&dateA=" + dateA );
        if (content) {
            $('#select_garantieNomProduit').append( content);
            $('#field_garantieNomProduit').removeClass('cache');
            $('#field_error_garantieProduit').addClass('cache');
            $('#field_error_garantieProduit td').empty();
        } else {
            $('#field_error_garantieProduit td').empty();
            $('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_error_garantieNomProduit' ));
        }
    }
}

function completeCity( elem, val) {

    if (val.length >= 4) {

        $.ajax({
            type: 'post',
            data: {cp : val},
            url: path_relative_root + 'ajax_autocomplete_city.php',
            success: function( response) {

                globalString = $.parseJSON(response);

                if (response != "") {

                    var lengthSting = globalString.length;
                    var value_return = '<select onchange="' + event + '" id="select_list_' + type + '" name="select_list_' + type + '"><option value="">Choisissez</option>';

                    for (var i = 0; i < lengthSting; i++) {
                        value_return += '<option value="' + globalString[i] + '">' + globalString[i] + '</option>';
                    }

                    value_return += '</select>';

                    $( "#" + elem ).html(value_return);

                    $('.selectStyled').each(function() {
                        $(this).selectStyled();
                    });

                }

            }
        });

    }

}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id
            ,prod_id: prod_id
            ,qte: qte
        },
        url: path_relative_root + create_link('ajax_removeBasketProd'),
        success: function( response) {
            try{
                response = $.parseJSON( response );
            }
            catch( e) {
                console.error( 'parseJSON' );
                return;
            }

            var datas = response.datas;
            var $html = $( datas.html );
            $('#cart_top').html($html);
            CartShow();
        }
    });
}

function openCloseBloc( elem, id_bloc, class_bloc, is_detail, url_ajax, txt_open, txt_close, class_btn) {

    var data = {};

    if (is_detail) {
        var id = elem.attr('id');
        var type = 1;
        elemId = id.substring(id.indexOf('_')+1);

        if (class_bloc == 'order_detail') {
            type = $('#order_type_'+elemId).val();
            data.cdeId = elemId;
        }
        else {
            data.mId = elemId;
        }

        data.type = type;

        id_bloc = id_bloc + '_' + elemId;
        id_wrapper = 'wrapper' + '_' + elemId;
    } else {
        id_wrapper = id_bloc.replace('bloc', 'wrapper');
    }

    if (!(elem).hasClass('actif')) {

        if (is_detail) {

            $('.'+class_bloc).not($('#'+id_bloc)).slideUp();
            $('.'+class_btn).not(elem).removeClass('actif');
            $('.'+class_btn).not(elem).text(txt_open);

        }

        if ($('#'+id_bloc).html() == '') {

            if (url_ajax != '') {

                $.ajax({
                    type: 'post',
                    url: path_relative_root+url_ajax,
                    data : data,
                    success: function( response) {
                        $('#'+id_bloc).html(response).slideDown();
                        $('#' + id_wrapper).addClass('bloc-opened');
                    }
                });

            }
            else {
                $('#' + id_bloc).slideDown();
            }

        }
        else {
            $('#' + id_bloc).slideDown();
        }

        (elem).addClass('actif');
        $('#' + id_wrapper).addClass('bloc-opened');
        (elem).text(txt_close);
    } else {
        $('.'+class_bloc).slideUp();
        (elem).removeClass('actif');
        $('#' + id_wrapper).removeClass('bloc-opened');
        (elem).text(txt_open);
    }

}
function sendMessage(id) {

    //disable the default form submission

    var mId = id;
    $('.loader_reponse_contact').css("display", "block");
    $('#envoyer_rep').addClass('active');

    if ($('#msg_txt_'+id).val() != '') {
        var upload_fail = ajax_file( 'ajax_sprintf.php?arg1=contact_msg_pj_fail' );
        var formData = new FormData($('form#data')[0]);
        $.ajax({
            url: path_relative_root+create_link('message_detail'),
            type: 'POST',
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function( response) {
                if (response == '1') {
                    $('#msg_conf_' + id).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_envoyer' ));
                    $('#msg_conf_' + id).show();
                    $('#msg_conf_' + id).addClass('active');
                    $("#msg_txt_" + id).val(' ');
                    clearInput('filepj1');
                    $('.loader_reponse_contact').css("display", "none");
                    $('#envoyer_rep').addClass('active');
                    window.setTimeout(function () {

                        window.location.reload();
                    }, 3000);
                    return false;
                }
                else if (response == '3') {
                    clearInput('filepj1');
                }
                else {
                    $('#msg_conf_' + mId).html(ajax_file( 'ajax_sprintf.php?arg1=contact_msg_fail' ));
                    $('#msg_conf_' + mId).show();
                }
            }
        });
    } else {
        $('.loader_reponse_contact').css("display", "none");
        $('#contact_msg_text_vide').css('color','red').fadeIn();
        setTimeout(function() {
            formMessageReset(id);
        }, 5000);
        $('#msg_conf_'+mId).hide();
    }
}

function formMessageReset(id) {
    $('#contact_msg_text_vide').fadeOut();
    if (! $('#msg_conf_' + id).hasClass('active')) {
        $('#envoyer_rep').removeClass('active');
    }
}

function clearInput(ctrlId) {

    var old = document.getElementById(ctrlId);
    var newElm = document.createElement('input');
    newElm.type = "file";
    newElm.id = ctrlId;
    newElm.name = old.name;
    newElm.className = old.className;
    newElm.style.paddingLeft = '33px';
    newElm.style.paddingTop = '-2px';
    newElm.style.float = 'left';

    old.parentNode.replaceChild(newElm, old);
}

function cancelMessage(id) {
    $('#msg_txt_'+id).val('');
}

function uploads() {
        var fd = new FormData;
        var file = document.getElementById('files').files[0];
        var mId = document.getElementById('mId').value;
        var xhr = new XMLHttpRequest();
        var url = path_relative_root + create_link('message_detail');

        xhr.file = file; // not necessary if you create scopes like this
        xhr.addEventListener('progress', function(e) {
            var done = e.position || e.loaded, total = e.totalSize || e.total;
        }, false);
        if (xhr.upload) {
            xhr.upload.onprogress = function(e) {
                var done = e.position || e.loaded, total = e.totalSize || e.total;
                console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done/total*1000)/10) + '%');
            };
        }
        xhr.onreadystatechange = function(e) {
            if (4 == this.readyState) {
                console.log(['xhr upload complete', e]);
            }
        };
        xhr.open('post', url , true);
        fd.append('file', file);
        fd.append('message_id', mId);
        fd.append('act','rep');
        xhr.send(fd);
}

function reloadDepartment() {

    var html_dpt = '';

    $.ajax({
        url: path_relative_root + 'ajax_get_country_info.php',
        type: 'POST',
        data: 'country='+$('#search_pays').val(),
        success: function( response) {

            html_dpt = '<option value="">'+response.text+'</option>';

            $.each(response.data, function(num, name) {
                html_dpt += '<option value="'+num.substring(1)+'">'+name+'</option>';
            });

            $('#departement').html(html_dpt);
        }
    });
}

function getAllProduct( type) {

    var value = $('#' + type + '_input').val();
    var min_length = $('#' + type + '_input').data('minlength');

    if (min_length == '')
        min_length = 0;

    // Construction des parametre à passer au script
    var values = { search : value, all_product : true };

    // On recherche à partir du nombre de caractere definie sur le champs
    if (value.length >= min_length) {

        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_' + type + '.php',
            'async': false,
            'success': function(response) {
                autoCompleteAll(response, type, value);
            }
        });

    }
}

function load_lightbox_overlay_exit() {
    var html = ajax_file(path_relative_root + create_link('ajax_display_lightbox_overlay_exit'));
    if (html) {
        $('#lightbox_overlay_exit')
            .html( html)
            .appendTo('body');
    }
}
function display_lightbox_overlay_exit() {

    var $lighbox = $('#lightbox_overlay_exit'),
        $shad = $( '#shad' );

    if ($lighbox.html() == '' )
        load_lightbox_overlay_exit();

    $shad
        .show()
        .bind('click', function() {close_lightbox_overlay_exit()});
    $lighbox
        .show();
}

function send_form_lightbox_overlay_exit() {
    $.ajax({
        type: 'post',
        data: {
            email: $('#form_lightbox_overlay_exit #email').val()
        },
        url: path_relative_root + create_link('ajax_display_lightbox_overlay_exit'),
        success: function(response) {
            $('#lightbox_overlay_exit').html(response);
        }
    });
}

function close_lightbox_overlay_exit() {

    var $lighbox = $('#lightbox_overlay_exit'),
        $shad = $('#shad');

    $shad.hide();
    $lighbox.hide();
}

function ajax_achat_express(reference, is_alliance, code_couleur, idprod) {
    $.ajax({
        type: "get",
        url:
            path_relative_root +
            "ajax_achat_express.php?ref=" +
            reference +
            "&couleur=" +
            code_couleur,
        success: function(response) {
            $('#site_global_wrap #product_selects').hide();
            var $lighbox = $("#lightbox_achat_express");
            var lighbox_id = "#lightbox_achat_express";
            var $shad = $("#shad");
            if (!$lighbox.length) {
                $("body").append('<div id="lightbox_achat_express"></div>');
                $lighbox = $("#lightbox_achat_express");
            }
            openMultiShad("lightbox_achat_express");
            $lighbox
                .html('<div class="express_response">' + response + "</div>")
                .appendTo("body");
            $('.itm_highlights', lighbox_id).remove();
            $('.wrapper_delivery', lighbox_id).remove();
            $('#btn_retour_product', lighbox_id).remove();
            $('#zoom_container', lighbox_id).remove();
            $('#loupe', lighbox_id).remove();
            $('#lightbox_stock_alert', lighbox_id).remove();
            $('#visual_scroller_wrapper', lighbox_id).append($('.frz_nocache', lighbox_id));
            $('#visual_scroller_wrapper', lighbox_id).append($('.pdt_icon_btm', lighbox_id));
            $('.find_retailer', lighbox_id).attr('onclick', 'location.href="'+path_relative_root + create_link('search_shop')+'"');
            setTimeout(function() {
                checkSubmitState();
                $('.netreviews_stars', lighbox_id).on('click', function () {
                    location.href=$('.itm_go_product', lighbox_id).attr('href')+"#avProductWrapper";
                });
            }, 500);
        },
    });
}

var price_min = $('input[type=hidden][name=initial_min_price]').val();
var price_max = $('input[type=hidden][name=initial_max_price]').val();

function deleteFilter() {
    $('#amount1').val(price_min);
    $('#amount2').val(price_max);
    $('.'+$(this).attr('data-obj_id')).click();

    // remise en position du slider
    $("#slider-range").slider("option", { min: parseInt(price_min), max: parseInt(price_max), values: [parseInt(price_min), parseInt(price_max)], });

    // remise au min/max des valeurs
    $('.handler_price.min').text(price_min + devise);
    $('.handler_price.max').text(price_max + devise);

    if (typeof wShop !== 'undefined' && typeof wShop.$refs.wRangeSlider !== 'undefined') {
        wShop.$refs.wRangeSlider.reloadTemplate();
        wShop.$refs.wRangeSlider.refreshPricesRange();
    }

    // remise en position du slider
    $('.ui-slider-range').css({'left':0, 'width':'100%'});
    $('.handler_price.min').parent().css('left', 0);
    $('.handler_price.max').parent().css('left','100%');
}

function deleteAllFilter(fromSearch = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();
            }
        }
    });

    deleteFilter();

    if (fromSearch === false) {
        generateNewBlocProd.call(form, 0);
    }
}

function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);
    
    const items = document.querySelectorAll('.formes_container .form_itm');

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
                // Remove active filters counters
                if ($("#filter_sticky  .filtre_elem .count_filters").length) {
                    $("#filter_sticky  .filtre_elem .count_filters").remove();
                }
            }
        }
    });

    var prix_init_min = $('input[name="initial_min_price"]').val();
    var prix_init_max = $('input[name="initial_max_price"]').val();
    var minPriceHandler = $('#slider-range .ui-slider-handle:first');
    var maxPriceHandler = $('#slider-range .ui-slider-handle:last');

    $("#amount1").val(prix_init_min);
    $("#amount2").val(prix_init_max);
    $("#slider-range").slider("option", {
        min: parseInt(prix_init_min),
        max: parseInt(prix_init_max),
        values: [parseInt(prix_init_min), parseInt(prix_init_max)],
    });

    minPriceHandler.html('<span class="handler_price">'+prix_init_min+'€</span>');
    maxPriceHandler.html('<span class="handler_price">'+prix_init_max+'€</span>');

    $('a.tri_price.actif').removeClass('actif');

    if (document.getElementById('order_prix') && document.getElementById('order_prix').value != '0') {
		document.getElementById('order_prix').value = 0;
	}

    $( ".selected_options input[type='checkbox']" ).each(function( index ) {
        $(this).prop( "checked", false );
    });

    items.forEach(i => i.classList.remove('selected'));

    // Call generateNewBlocProd
    generateNewBlocProd.call(form, 0);
}

$('.price_input_reset').click(function () {
    $('#amount1').val($('input[type=hidden][name=initial_min_price]').val() + '€');
    $('#amount2').val($('input[type=hidden][name=initial_max_price]').val() + '€');

    generateNewBlocProd.call(form, 0);
});
